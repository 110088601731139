import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import Toolbar from '@mui/material/Toolbar';
import ApiIcon from '@mui/icons-material/Api';
import LogoutIcon from '@mui/icons-material/Logout';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TryIcon from '@mui/icons-material/Try';
import { useTheme } from '@mui/material/styles';

import { MenuNavItemLink } from './RouterElements';
import CollapsibleMenu from './CollapsibleMenu';

export default function NavDrawer() {
  const theme = useTheme();

  return (
    <Box sx={{ bgcolor: theme.palette.background.paper }}>
      <Toolbar />
      <Divider />
      <MenuList>
        <MenuNavItemLink to="/" icon={<TryIcon />}>
          AI Doc Q&A
        </MenuNavItemLink>
        <MenuNavItemLink to="/search" icon={<PlagiarismIcon />}>
          Search Documents
        </MenuNavItemLink>
        <CollapsibleMenu title="Developer Tools">
          <MenuList>
            <MenuNavItemLink to="/apis" icon={<ApiIcon />}>
              APIs
            </MenuNavItemLink>
            <MenuNavItemLink to="/viewer" icon={<DocumentScannerIcon />}>
              Viewer
            </MenuNavItemLink>
          </MenuList>
        </CollapsibleMenu>
        <CollapsibleMenu title="Marketing">
          <MenuList>
            <MenuNavItemLink to="/tableau" icon={<DashboardIcon />}>
              Tableau
            </MenuNavItemLink>
          </MenuList>
        </CollapsibleMenu>
      </MenuList>
      <Divider />
      <MenuList>
        <MenuNavItemLink to="/account" icon={<PermIdentityIcon />}>
          My Account
        </MenuNavItemLink>
        <MenuNavItemLink to="/logout" icon={<LogoutIcon />}>
          Logout
        </MenuNavItemLink>
      </MenuList>
    </Box>
  );
}
