import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadFile } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography'; 
import { useInterval } from '../../hooks';
import { getDocumentByHash } from '../../interactors/documents';
import { uploadDocument } from '../../interactors/source_sf';
import { getDocumentMetadata } from '../../interactors/documents';

import * as utils from './utils';
import { OCRApiDocument } from '../../types/ocr_document';
import { DocrioUpload } from '../../types/docrio_upload';


interface DocResolverProps {
  checksum: string;
  blob: Blob;
  onResolve: (checksum: string, doc: OCRApiDocument) => void;
  onDelete: (checksum: string) => void
}


export const DocResolver = ({ checksum, blob, onResolve, onDelete }: DocResolverProps) => {
  const [pendingDoc, setPendingDoc] = useState<string>('');
  const [percentDone, setPercentDone] = useState<number>(0);
  const [doc, setDoc] = useState<null | OCRApiDocument>(null);
  
  useEffect(() => {
    const findOrUploadDoc = async () => {
      let doc;
      try {
       doc = await getDocumentByHash(checksum);
      } catch (e: any) {
        if (e?.detail != 'Not found') throw e;
      }
      if (doc && utils.hasDocumentBeenOCRd(doc)) return setDoc(doc);
      const upload = await uploadDocument(blob);
      setPendingDoc(upload?.document_id ?? '')
    };
    findOrUploadDoc();
  }, []);

  useInterval(() => {
    const checkPendingDocProgress = async () => {
      const document = await getDocumentMetadata(pendingDoc);
      const percentDone = utils.getPercentDone(document);
      setPercentDone(percentDone);
      if (percentDone === 100) {
        setTimeout(() => {
          setPendingDoc('');
          setDoc(document)
        }, 200);
      }
    };
    checkPendingDocProgress();
  }, pendingDoc ? 2000 : null);

  useEffect(() => {
    if (doc) onResolve(checksum, doc);
  }, [doc]);

  
  const displayedPercent = Math.max(percentDone, 5);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <>
          {
            doc && <IconButton
              disabled
              sx={(theme) => ({'&:disabled': { color: theme.palette.text.secondary }})}
            >
              <CheckCircleIcon />
            </IconButton>
          }
          <IconButton
            edge="end"
            onClick={() => onDelete(checksum)}
            sx={{marginLeft: '5px' }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      }
    >
      <ListItemIcon>
        <UploadFile />
      </ListItemIcon>
      <div style={{ width: '60%' }}>
        <div>
          <Typography>{blob.name}</Typography>
          <Typography variant="body2">{utils.showFileSize(blob)}{!doc && ` • ${displayedPercent}%`}</Typography>
        </div>
        {!doc && <LinearProgress
          variant="determinate"
          value={displayedPercent}
        />}
      </div>
    </ListItem>
  )
};

