import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { NotificationContext } from '../App';
import { SearchResults } from '../pages/search/SearchResults';

interface Props {
  isModalOpen: boolean;
  toggleShowModal: () => void;
  handleSelect?: (selections: any[]) => void;
  handleSubmit: () => Promise<void>;
  buttonLabel?: string;
}
export const ESSearchModal = (props: Props) => {
  const { isModalOpen, toggleShowModal, handleSelect, handleSubmit, buttonLabel = 'Get Document Texts' } = props;

  const [esSelections, setESSelections] = useState([]);
  const [isModalRetrievingText, setIsModalRetrievingText] = useState(false);
  const { setToastMessage } = useContext(NotificationContext);

  useEffect(() => {
    handleSelect?.(esSelections);
  }, [esSelections.length]);

  const handleSubmitClick = async () => {
    setIsModalRetrievingText(true);
    try {
      await handleSubmit();
    } catch (e) {
      setToastMessage({
        message: JSON.stringify(e),
        severity: 'error',
      });
    } finally {
      setIsModalRetrievingText(false);
    }
  };

  if (!isModalOpen) return null;

  return (
    <Modal open={isModalOpen} onClose={toggleShowModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Card
        style={{
          position: 'absolute',
          top: '20%',
          left: '25%',
          transform: 'translate(-20%, -10%)',
          width: '75%',
        }}
      >
        {!isModalRetrievingText && <CardHeader title="Search Documents" />}
        {isModalRetrievingText && <CircularProgress sx={{ display: 'block', margin: '0 auto' }} />}
        <CardContent
          sx={{
            overflow: 'scroll',
            display: isModalRetrievingText ? 'none' : 'block',
            maxHeight: '400px',
          }}
        >
          <SearchResults handleSelect={setESSelections} />
        </CardContent>
        {!isModalRetrievingText && (
          <CardActions sx={{ float: 'right' }}>
            <Button onClick={handleSubmitClick} disabled={isModalRetrievingText || !esSelections?.length}>
              {buttonLabel}
            </Button>
          </CardActions>
        )}
      </Card>
    </Modal>
  );
};
