import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepIcon from '@mui/material/StepIcon';
import StepContent from '@mui/material/StepContent';
import DescriptionIcon from '@mui/icons-material/Description';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FlareIcon from '@mui/icons-material/Flare';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'; 
import Icon from '@mui/material/Icon';

import classNames from 'classnames';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { StepConnector } from "@mui/material";

import './EmptyContent.css';


const steps = [
  {
    label: 'Step 1',
    icon: <DescriptionIcon className='icon'/>,
    content: <Typography><span className='bold'>Upload </span> a PDF, <span className='bold'>enter</span> text, or <span className='bold'>select</span> a document from Docrio</Typography>,
    styleClassName: 'eastToWest'
  },
  {
    label: 'Step 2',
    icon: <AutoFixHighIcon className='icon'/>,
    content: <Typography><span className='bold'>Type</span> a prompt like “summarize document” or “find damages”</Typography>,
    styleClassName: 'southToNorth',
  },
  {
    label: 'Step 3',
    icon: <FlareIcon className='icon'/>,
    content: <Typography><span className='bold'>See</span> the results and <span className='bold'>share</span> them with your colleagues via email</Typography>,
    styleClassName: 'northToSouth',
  },
]

function IconContainer(props) {
  return (
        <Box className={classNames(['arrowbox', props.className])} sx={{ p: 1 }}>
          <Box
            className={classNames(['iconContainer', props.className])}
            sx={(theme) => ({ backgroundColor: theme.palette.background.icon})}
          >
            <Box className='centerIcon'>
              {props.children}
            </Box>
          </Box>
      </Box>
  );
}

export default function EmptyContent() {

  return (
    <Card elevation={2} sx={(theme) => ({ p: 4, m: 4, flex: 1, backgroundColor: theme.palette.background.secondary })}>
      <CardContent sx={{ display: 'flex', alignItems: 'center'}}>
        <Stepper
            orientation="vertical"
            sx={(theme) => ({
              width: '80%',
              margin: '0 auto',
              '& .arrowbox': {
                borderTopColor: theme.palette.text.secondary,
                borderLeftColor: theme.palette.text.secondary,
                ':before': {
                  borderLeftColor: theme.palette.text.secondary,
                }
              },
              '& .MuiStepLabel-labelContainer': {
                marginTop: '-40px',
                paddingLeft: '10px'
              },
              '& .MuiStepContent-root': {
                marginLeft: '30px',
                paddingLeft: '40px',
                borderLeft: `1px dashed ${theme.palette.text.secondary}`,
              },
              '& .MuiStepConnector-line': {
                marginTop: '1px',
                marginLeft: '18px',
                paddingLeft: '40px',
                borderLeft: `1px dashed ${theme.palette.text.secondary}`
              },
              '& .MuiStepContent': {
                paddingLeft: '30px',
                borderLeft: `1px dashed ${theme.palette.text.secondary}`
              },
              '& .MuiCollapse-wrapper': {
                position: 'relative'
              },
              '& .MuiCollapse-wrapperInner': {
                position: 'relative',
                top: '-50px',
                maxWidth: '235px'
              },
              '& .MuiStepContent-last': {
                border: 'none'
              },
            })}
        >
          {steps.map((step, idx) => {
            return (
              <Step key={`step_${idx}`} active>
                <StepLabel
                  StepIconComponent={(props) => <IconContainer className={step.styleClassName}>{step.icon}</IconContainer>}
                  sx={{ flexDirection: 'row' }}
                >
                  <Typography sx={(theme) => ({ color: theme.palette.text.secondary, fontSize: '12px'})}>
                    <span className='bold'>{step.label.toUpperCase()}</span>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Typography variant="body2">{step.content}</Typography>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </CardContent>
    </Card>
  );
}
