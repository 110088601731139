import omit from 'lodash.omit';
import { APIUrl, getAPIHeaders } from './utils';
import { DocrioUpload } from '../types/docrio_upload';
import { Healthcheck } from '../types/APIs';

export const SourceSFAPIUrl = APIUrl.replace('api', 'sf.api');

export const healthCheck = async (): Promise<Healthcheck> => {
  const response = await fetch(`${SourceSFAPIUrl}healthcheck`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  const result = await response.json();
  if (!response.ok) throw result;
  return result;
}

export const uploadDocument = async (file: Blob, env: string = 'prod'): Promise<DocrioUpload> => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const response = await fetch(`${SourceSFAPIUrl}receiver/upload-file?salesforce_env=${env}`, {
    method: 'POST',
    headers: omit(getAPIHeaders(), 'Content-Type'), // setting content-type to multipart-form errors
    body: formData
  });
  const result = await response.json();
  if (!response.ok) throw result;
  if (!result.success) throw Error('Upload unsuccessful');
  return result;
};
