import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import UploadFile from '@mui/icons-material/UploadFile';
import Typography from '@mui/material/Typography';
import { useDropzone } from 'react-dropzone';

export function Dropzone({ onDrop }: { onDrop: () => void }) {

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop, accept: { 'application/pdf': ['.pdf'] }
  })

  const onClick = getRootProps().onClick;
  const rootProps = {
    ...getRootProps(), onClick: () => {},
  };

  return (
    <Box
      {...rootProps}
      sx={(theme) => ({
        borderWidth: isDragActive ? '3px': '1px',
        borderStyle: 'dashed',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '10px',
        p: 2,
      })}
    >
        <input {...getInputProps()} multiple />
        <UploadFile />
        <Typography><Link onClick={onClick}>Click to upload</Link> or drag and drop</Typography>
        <Typography sx={{ marginTop: '20px' }}>{'PDF Files (max. 20MB)'}</Typography>
    </Box>
  )
}
