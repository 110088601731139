import React, { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse, 
  Link,
  List,
  ListItem,
} from '@mui/material';

export function CollabsableRRFItem(props) {
  const [isOpenRel, setIsOpenRel] = useState(false);
  const toggleOpenRel = () => setIsOpenRel(!isOpenRel);
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const stringForType = (data) => {
    if (data._source?.isDoc === undefined || data._source?.isDoc === false) {
      return 'Type: Semantic';
    }
    if (data._source?.isDoc) {
      return 'Type: Keyword';
    }
  }

  return (
    <Card sx={{width:'100%'}} elevation={2} >
      <CardActions >
        <Button         
          color="info" 
          fullWidth={true}
          onClick={toggleOpenRel}
        >
         Found {props.otherHits.length} other related passages
        </Button>
      </CardActions>
      <CardContent>
        <Collapse in={isOpenRel} timeout="auto" unmountOnExit> 
          {props.otherHits.map((data, index) => (
            <Card elevation={4} sx={{m:4}}>
            <CardHeader
          sx={{}}
          titleTypographyProps={{}}
          title ={
            <Link 
              color="inherit" 
              href={data._source?.metadata.redirect_url}
              target="_blank"
              className="linked-title">
              {data._source?.title}
            </Link>
          } 
          subheader={
            <>
             <Button onClick={toggleOpen}> Matter: {data._source?.metadata.matter_display_name}` {data._source?.metadata.matter_id}</Button>
            
            <Collapse in={isOpen} >
            <List > 
              <ListItem>{stringForType(data)}</ListItem> 
              <ListItem>Rank: {parseInt(data._rank)}</ListItem>
              <ListItem>Uploaded: {new Date(data._source?.metadata.uploaded_date).toLocaleString("en")}</ListItem>
              <ListItem>Last modified :{new Date(data._source?.metadata.last_modified_date).toLocaleString("en")}</ListItem>
            </List>
            </Collapse>
          </>}>
        </CardHeader>
              <CardContent>
                <p>
                {((data._source?.isDoc === undefined) || (data._source?.isDoc === false)) && (
                  <>{data._source?.text}</>
                )}
                {(data._source?.isDoc === true) && (
                  <>{data._source?.text.slice(0,1000)}</>
                )}
                </p>
                
              </CardContent>
            </Card>
          ))}
        </Collapse>
      </CardContent>
    </Card>
  );
}
