import { CognitoUser } from '@aws-amplify/auth';
import { EventEmitter } from 'events';
import { JWTClaims } from '../schemas/utils';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { datadogRum } from '@datadog/browser-rum'

declare interface JWTClaimsExtended {
  'cognito:groups': string[];
  email: string;
  given_name: string;
  family_name: string;
}

type JWTClaimsPlus = JWTClaims & JWTClaimsExtended;

class AuthStore extends EventEmitter {
  _user?: CognitoUser;
  _userInfo?: JWTClaims | null;
  _accessToken: string;
  _expireTime: number;
  _groups: string[];
  _createdOrg: Boolean = false;
  _email: string = '';
  _given_name: string = '';
  _family_name: string = '';
  _autoRefresh?: NodeJS.Timeout;

  constructor() {
    super();
    this._accessToken = '';
    this._expireTime = 0;
    this._groups = [];
  }

  _login() {
    this.emit('login', this);
  }

  _update() {
    this.emit('update', this);
  }

  _logout() {
    this.emit('logout', this);
  }

  loggedIn(): boolean {
    if (this._expireTime !== 0 && new Date(this._expireTime * 1000) > new Date()) return true;
    if (this._expireTime !== 0) this.logout();
    return false;
  }

  logout() {
    this._email = '';
    this._given_name = '';
    this._family_name = '';
    this._userInfo = null;
    this._groups = [];
    this._accessToken = '';
    datadogRum.clearUser();
    if (this._autoRefresh) {
      clearInterval(this._autoRefresh);
      this._autoRefresh = undefined;
    }
    if (this._user) this._user.signOut(() => this._logout());
  }

  async login(user: CognitoUser) {
    let token = '';
    if (user) {
      this._user = user;
    }
    const session = user.getSignInUserSession();
    if (session !== null) {
      token = session.getAccessToken().getJwtToken();
      if (token) {
        this._accessToken = token;
        this._expireTime = session.getAccessToken().getExpiration();
        if (this._expireTime * 1000 - Date.now() < 0) {
          this.logout();
        }
        const userInfo = user.getSignInUserSession()?.getIdToken().decodePayload() as JWTClaimsPlus;
        if (userInfo) {
          this._groups = Array.isArray(userInfo['cognito:groups']) ? userInfo['cognito:groups'] : [];
          this._email = userInfo['email'];
          this._given_name = userInfo['given_name'];
          this._family_name = userInfo['family_name'];
          datadogRum.setUser({
            id: this._email,
            name: this._given_name+' '+this._family_name,
            email: this._email,
          })
        }
        if (!this._autoRefresh) {
          this._autoRefresh = setInterval(() => {
            this.refresh();
          }, 1000 * 60 * 20);
        }
        this._login();
      }
    }
  }

  async refresh() {
    if (!this.user) {
      return Promise.reject('No user');
    }
    const session = this.user.getSignInUserSession();
    if (session === null) {
      return Promise.reject('No session');
    }
    const token = new CognitoRefreshToken({
      RefreshToken: session.getRefreshToken().getToken(),
    });
    return new Promise((resolve, reject) => {
      if (!this.user) {
        return reject('No user');
      }
      this.user.refreshSession(token, (err) => {
        if (err) {
          return reject(err);
        }
        if (!this.user) {
          return reject('No user');
        }
        this.login(this.user).then(resolve);
        // this.login(this.user);
      });
    });
  }

  isInGroup(groupID: string): boolean {
    return this._groups.findIndex((group) => group === groupID) > -1;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get expireTime(): number {
    return this._expireTime;
  }

  get groups(): string[] {
    return this._groups;
  }

  get isAdmin(): boolean {
    return this._groups.findIndex((group) => group === 'mx2_admin') > -1;
  }

  get user(): CognitoUser | undefined {
    return this._user;
  }

  get userInfo(): JWTClaims | null | undefined {
    return this._userInfo;
  }

  get firstName(): string {
    return this._given_name;
  }

  get lastName(): string {
    return this._family_name;
  }

  get email(): string {
    return this._email;
  }
}

const _store = new AuthStore();
export default _store;
