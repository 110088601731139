import React, { ChangeEvent, MouseEvent, SyntheticEvent, useContext, useState, useCallback, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { postSummarizeFromFile, postSummarizeFromText, postSummarizeRag, shareToEmail, getPDFExportLink, getModels } from '../../interactors/prompt-sandbox';
import { getDocumentText } from '../../interactors/documents';
import { healthCheck as sourceSFHealthcheck } from '../../interactors/source_sf';
import { healthCheck as docHealthcheck } from '../../interactors/documents';
import { NotificationContext } from '../../App';
import { ESSearchModal } from '../../components/ElasticsearchModal';
import EmptyContent from './EmptyContent';
import DocSelector from '../../components/DocSelector';
import { DocResolver } from '../../components/DocResolver';
import { calculateChecksum256 } from '../../components/DocResolver/utils';
import { Dropzone } from '../../components/Dropzone';


import * as helpers from '../../store/promptSandbox';
import { FormStateFields, ESDocumentSelection, PromptInputTypes, LLMModel } from '../../types/promptSandbox';
import { OCRApiDocument } from '../../types/ocr_document';
import { useInterval } from '../../hooks';

import { Uploads } from './types';

export default function HomePage() {
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [esSelections, setESSelections] = useState<ESDocumentSelection[]>([]);

  const [tab, setTab] = useState(PromptInputTypes.File);
  const [email, setEmail] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null);
  const [models, setModels] = useState<LLMModel[]>([]);
  const [uploads, setUploads] = useState<Uploads>({});
  const [form, setForm] = useState<FormStateFields>({ model_id: '', prompt_text: '' });
  const [result, setResult] = useState<Record<string, string>>({});
  const { setToastMessage } = useContext(NotificationContext);

  useEffect(() => {
    sourceSFHealthcheck();
    docHealthcheck();
  }, []);

  useInterval(() => {
    sourceSFHealthcheck();
    docHealthcheck();
  }, 30000);

  useEffect(() => {
    getModels().then(setModels).catch(error => {
      setToastMessage({ message: JSON.stringify(error), severity: 'error' });
    });
  }, []);

  useEffect(() => {
    if (!form.model_id) setForm({ ...form, model_id: models?.[0]?.model_id})
  }, [models]);

  const handleChangeForm = useCallback(
    (name: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      setForm({ ...form, [name]: value });
    },
    [form]
  );

  const handleSelectDocuments = (obj: object) => {
    if (!form?.document_ids?.length && !Object.keys(obj).length) return;
    setForm({ ...form, document_ids: Object.keys(obj) });
  };

  const handleDropFiles = async (drops: Blob[]) => {
    const newUploads = { ...uploads };
    const pendingChecks = drops.map(async (drop: Blob) => {
      const checksum = await calculateChecksum256(drop);
      if (!newUploads[checksum]) newUploads[checksum] = {
        blob: drop,
        document: null,
      };
      return;
    });
    await Promise.all(pendingChecks);
    setUploads(newUploads);
  };

  const handleDeleteFile = (checksum: string) => {
    if (!(checksum in uploads)) return;
    const newUploads = { ...uploads };
    delete newUploads[checksum]
    setUploads(newUploads);
  };

  const handleResolveFile = (checksum: string, document: OCRApiDocument) => {
    if (!(checksum in uploads)) return;
    const newUploads = { ...uploads };
    newUploads[checksum] = { ...newUploads[checksum], document };
    setUploads(newUploads);
  };

  const handleChangeTab = (_ev: SyntheticEvent<Element, Event>, value: number) => {
    setTab(value);
  };

  const handleCopyText = () => {
    navigator.clipboard.writeText(result?.result ?? '');
    setToastMessage({ severity: 'success', message: 'Copied!' });
  };

  const handleChangeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
    setEmail(ev?.target?.value);
  };

  const handleDownloadPDF = async () => {
    if (!result?.id) return;
    const { link } = await getPDFExportLink(result.id);
    const anchor = document.createElement('a');
    anchor.download = `Prompt Sandbox - ${new Date().toISOString()}.pdf`;
    anchor.href = link;
    anchor.click();
    anchor.remove();
  };

  const handleSendEmail = async () => {
    if (!email || !result?.id) {
      return setToastMessage({
        message: 'Valid email missing',
        severity: 'error',
      });
    }
    setEmailLoading(true);
    try {
      await shareToEmail(email, result.id);
      setToastMessage({
        message: 'Email sent!',
        severity: 'success',
      });
    } catch (err) {
      setToastMessage({
        message: JSON.stringify(err),
        severity: 'error',
      });
    } finally {
      setEmailLoading(false);
    }
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchor(event.currentTarget);
  };

  const toggleShowModal = useCallback(() => {
    if (isModalOpen) setESSelections([]);
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  const handleSubmit = useCallback(async () => {
    let func;
    switch (tab) {
      case PromptInputTypes.File:
        func = () => postSummarizeFromText(helpers.get_file_form_data(form, uploads));
        break;
      case PromptInputTypes.Text:
        func = () => postSummarizeFromText(helpers.get_text_input_form_data(form));
        break;
      case PromptInputTypes.DocumentId:
      default:
        func = () => postSummarizeFromText(helpers.get_doc_input_form_data(form));
        break;
    }
    setLoading(true);
    try {
      setResult(await func());
    } catch (err) {
      setToastMessage({ message: JSON.stringify(err), severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [form]);

  const handleGetDocumentTexts = async () => {
    try {
      const results = await Promise.all(esSelections.map((selection) => getDocumentText(selection.document_id)));
      const resultsTexts = results.map((result) => result.text);
      setForm({ ...form, input_text: resultsTexts.join('\n\n=============\n\n') });
      toggleShowModal();
    } catch (err) {
      setToastMessage({ message: JSON.stringify(err), severity: 'error' });
    }
  };

  const renderInputAndFiles = () => {
    return (
      <div>
        <Dropzone onDrop={handleDropFiles} />
        <List sx={{ marginTop: '10px'}}>
          {
            Object.keys(uploads).map((checksum => {
              const upload = uploads[checksum]
              return (
                <DocResolver
                  key={checksum}
                  checksum={checksum}
                  blob={upload.blob}
                  onDelete={handleDeleteFile}
                  onResolve={handleResolveFile}
                />
              );
            }))
          }
        </List>
      </div>
    );
  };

  const renderModal = () => {
    return <ESSearchModal isModalOpen={isModalOpen} toggleShowModal={toggleShowModal} handleSelect={setESSelections} handleSubmit={handleGetDocumentTexts} />;
  };

  const renderTextInput = () => {
    if (tab !== PromptInputTypes.Text) return null;
    return (
      <TextField
        id="outlined-multiline-static"
        label="Enter text..."
        sx={{ alignItems: 'top' }}
        multiline
        rows={10}
        defaultValue=""
        placeholder=""
        value={form.input_text}
        onChange={handleChangeForm('input_text')}
        fullWidth
      />
    );
  };

  const renderDocumentIdInput = () => {
    if (tab !== PromptInputTypes.DocumentId) return null;
    return <DocSelector onSelect={handleSelectDocuments} />;
  };

  const renderSubmitAction = () => {
    const CHARACTER_LIMIT = 150;
    const uploadsList = Object.values(uploads);
    const docUploadsEmptyOrAwaiting = tab === PromptInputTypes.File && 
        (!uploadsList.length || !uploadsList.every(upload => upload.document));
    const isDisabled = docUploadsEmptyOrAwaiting || !form?.prompt_text;
    const tooltipTitle = isDisabled ? "Make sure you have included a prompt and that your documents are selected and have finished loading before submitting." : "";
    console.log('isDisabled', tooltipTitle)
    return (<Tooltip title={tooltipTitle}>
      <span>
        <Button
          color="info"
          onClick={handleSubmit}
          style={{ marginTop: '10px', float: 'right' }}
          disabled={isDisabled}
        >
          Submit
        </Button>
      </span>
    </Tooltip>);
  };

  const renderForm = () => {
    return (
      <Card sx={{ minWidth: '600px' }}>
        <CardContent>
          <div>
            <div style={{ minWidth: '500px' }}>
              <Tabs value={tab} onChange={handleChangeTab} aria-label="prompt-input-select">
                <Tab label="PDF" />
                <Tab label="Text Input" />
                <Tab label="Find Docs" />
              </Tabs>
            </div>
          </div>
          {renderInputAndFiles()}
          {renderTextInput()}
          {renderDocumentIdInput()}
          <TextField
            id="outlined-multiline-static"
            label="Enter prompt"
            multiline
            rows={4}
            defaultValue=""
            placeholder=""
            value={form.prompt_text}
            onChange={handleChangeForm('prompt_text')}
            fullWidth
            sx={{ mt: 2 }}
          />
        </CardContent>
        <CardHeader
          title=""
          subheader={
            <div>
              <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                  <Typography>Advanced Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    select
                    onChange={handleChangeForm('model_id')}
                    SelectProps={{ native: true }}
                    value={form.model_id}
                    fullWidth
                  >
                    {models.map((model, key) => (
                      <option key={key} value={model.model_id}>
                        {model.name}
                      </option>
                    ))}
                  </TextField>
                </AccordionDetails>
              </Accordion>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {renderSubmitAction()}
              </div>
            </div>
          }
        />
      </Card>
    );
  };

  const renderPopoverContent = () => {
    return (
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={popoverAnchor}
        open={!!popoverAnchor}
        onClose={handleClosePopover}
      >
        <Box sx={{ p: 2 }}>
          <TextField placeholder="Email" variant="standard" onChange={handleChangeEmail} value={email} />
          <Button color="info" onClick={handleSendEmail} disabled={emailLoading}>
            <SendIcon />
          </Button>
          <Button color="info" onClick={handleCopyText}>
            <ContentCopyIcon />
          </Button>
          <Button color="info" onClick={handleDownloadPDF}>
            <PictureAsPdfIcon />
          </Button>
        </Box>
      </Popover>
    );
  };

  const renderSharePopoverButton = () => {
    if (!result?.id) return null;
    return (
      <CardHeader
        title={
          <>
            <Button color="info" onClick={handleOpenPopover}>
              Share Results
            </Button>
            {renderPopoverContent()}
          </>
        }
        sx={{ float: 'right' }}
      />
    );
  };

  const renderResult = () => {
    if (loading)
      return (
        <div style={{ padding: '50px' }}>
          <CircularProgress />
        </div>
      );
    if (!result?.id) {
      return <EmptyContent />;
    }
    return (
      <Card elevation={2} sx={{ p: 4, m: 4, flex: 1 }}>
        {renderSharePopoverButton()}
        <CardContent>
          <Typography variant="body1" color={'primary'} alignContent={'left'} sx={{ pl: 1, display: 'inline-flex', textWrap: 'wrap' }} component="pre">
            {result?.result}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Typography variant="h3" color={'primary'} alignContent={'center'} sx={{ pl: 1, display: 'inline-flex' }}>
          Prompt Sandbox
        </Typography>
      </Paper>
      <div style={{ display: 'flex' }}>
        {renderForm()}
        {renderResult()}
      </div>
      {renderModal()}
    </>
  );
}
