import { APIUrl, getAPIHeaders } from './utils';

export const MattersAPIURL = APIUrl.replace('api', 'sf-sync.api');

export const fetchCurrentUser = async () => {
  const response = await fetch(`${MattersAPIURL}user/current`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch current user');
  }

  const data = await response.json();
  return data;
};

export const fetchCaseMatters = async (userId: string) => {
  const response = await fetch(`${MattersAPIURL}user/${userId}/matters`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });

  if (!response.ok) {
    if (response.status === 404) {
      // No matters found, return an empty array
      return [];
    }
    throw new Error('Failed to fetch case matters');
  }

  const data = await response.json();
  return data.map((matter: any) => matter.case_matter);
};
