import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchCurrentUser, fetchCaseMatters } from '../../interactors/user';

import {
  Container,
  IconButton,
  Paper,
  InputLabel,
  FormControl,
  CardContent,
  OutlinedInput,
  InputAdornment,
  Typography,
  CardHeader,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';

import './SearchResults.css';
import { RRFResult } from '../../components/search/RRFResult';
import { NotificationContext } from '../../App';

import { getSearchResults } from '../../interactors/prompt-sandbox';

import { SearchTypes } from '../../types';
import { detectedTypes } from '../../constants';

export const SearchResults = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchInput = decodeURIComponent(searchParams.get('q') || '');
  const initialSearchType = searchParams.get('t') || SearchTypes.RRF;
  const initialSearchMatter = searchParams.get('my_matters') || '';
  const initialSearchMatterName = searchParams.get('matterName') || '';
  const initialDocId = searchParams.get('docId') || '';
  const initialDetectedTypes = searchParams.get('detectedTypes') ? searchParams.get('detectedTypes').split(',') : [];
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(initialSearchInput);
  const [searchType, setSearchType] = useState(initialSearchType);
  const [myMatter, setMyMatter] = useState(initialSearchMatter);
  const [matterName, setMatterName] = useState(initialSearchMatterName);
  const [docId, setDocId] = useState(initialDocId);
  const [detectedType, setDetectedType] = useState(initialDetectedTypes);
  const [userId, setUserId] = useState<string | null>(null);
  const [caseMatters, setCaseMatters] = useState<string[] | null>(null);
  const { setToastMessage } = useContext(NotificationContext);
  const [selections, setSelections] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [elasticResults, setElasticResults] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  
  const updateSearchParams = useCallback((newParams) => {
    setSearchParams({ ...searchParams, ...newParams });
  }, [searchParams]);

  useEffect(() => {
    const newSearchType = searchParams.get('t');
    if (newSearchType !== searchType) setSearchType(newSearchType);
  }, [searchParams]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const currentUserData = await fetchCurrentUser();
        setUserId(currentUserData.id);
      } catch (error) {
        setToastMessage({ message: JSON.stringify(error), severity: 'error' });
      }
    };
    fetchUserInfo();
  }, []);

  const handleCheckResult = (id, data) => {
    if (!selections[id]) {
      setSelections({
        ...selections,
        [id]: data,
      });
      return;
    }
    const newSelections = { ...selections };
    delete newSelections[id];
    setSelections(newSelections);
  };

  useEffect(() => {
    props?.handleSelect?.(
      Object.values(selections).map((selection) => (
        {
          document_id: selection[0]._source.metadata.document_id,
          version_id: selection[0]._source.metadata.document_version_id,
        }
      ))
    );
  }, [selections]);

  const handleQueryChange = (e) => setSearchInput(e.target.value);

  const handleDetectedTypeChange = (event) => {
    setDetectedType(event.target.value);
  };

  const fetchMatters = async () => {
    try {
      const matters = await fetchCaseMatters(userId);
      setCaseMatters(matters);
    } catch (error) {
      setToastMessage({ message: JSON.stringify(error), severity: 'error' });
    }
  };

  const conditionalFetchMatters = useCallback(async () => {
    if (myMatter !== "my_matters" || (caseMatters && caseMatters.length > 0)) return;
    await fetchMatters();
  }, [myMatter, caseMatters, userId]);

  useEffect(() => {
    conditionalFetchMatters();
  }, [conditionalFetchMatters]);

  const searchButtonClick = () => {
    setSelections({});

    const newSearchParams = {
      t: SearchTypes.RRF,
      q: encodeURI(searchInput),
    };

    if (docId) {
      newSearchParams.docId = encodeURI(docId)
    }

    if (matterName) {
      newSearchParams.matterName = encodeURI(matterName)
    }

    if (myMatter === "my_matters") {
      newSearchParams.my_matters = myMatter;
    }

    if (detectedType.length > 0) {
      newSearchParams.detectedTypes = detectedType.join(',')
    }

    updateSearchParams(newSearchParams);
    runQuery(SearchTypes.RRF)
  };

  useEffect(() => {
    if (initialSearchInput) {
      searchButtonClick();
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchButtonClick();
    }
  };

  const runQuery = (qtype) => {
    setSearchResults([]); //wipes the old results off the page
    if (!searchInput) return;
    setShowSpinner(true);

    switch (qtype) {
      case SearchTypes.RRF:
        getSearchResults('rrf', searchInput, matterName, docId, detectedType, myMatter === "my_matters" ? caseMatters || [] : [])
          .then((message) => {
            //group by document ID and sort by rank.
            let grouped = Object.groupBy(
              message.hits.hits,
              ({ _source }) => _source.metadata.document_id
            );
            let g_keys = Object.keys(grouped);
            let g_array = [];
            g_keys.forEach((key) => g_array.push(grouped[key]));
            g_array.sort((a, b) => a[0]._rank < b[0]._rank);
            setSearchResults(g_array);
            setElasticResults(message.hits.hits);
            setShowSpinner(false);
          }).catch((error) => {
            setToastMessage({ message: JSON.stringify(error), severity: 'error' });
            setShowSpinner(false);
          });
        break;

      default:
        setShowSpinner(false);
    }
  };

  const renderFilters = () => {
    const numFiltersActive = [myMatter, matterName, docId, detectedType.length > 0].filter(Boolean).length;
    return (
      <>
        <CardHeader
          title={
            <Typography>
              {'Filters' + (numFiltersActive ? ` (${numFiltersActive})` : '')}
            </Typography>
          }
          action={
            <IconButton
              onClick={() => setFiltersOpen(!filtersOpen)}
              aria-label='expand'
              size='small'
            >
              {filtersOpen ? <KeyboardArrowUpIcon />
                : <KeyboardArrowDownIcon />}
            </IconButton>
          }
        />
        <Collapse in={filtersOpen}>
          <CardContent className="filters-container">
          <FormControl variant="outlined" className="filter-item" fullWidth>
            <InputLabel id="matters-label" shrink={true}>Matters</InputLabel>
              <Select
                labelId="matters-label"
                value={myMatter || ""} // Set default to empty string if no value is selected
                onChange={(e) => setMyMatter(e.target.value)}
                label="Matters"
                displayEmpty
                notched
              >
                <MenuItem value="">
                  All Matters {/* This will be the default option */}
                </MenuItem>
                <MenuItem value="my_matters">{`My Matters ${userId ? `(user id: ${userId})` : ''}`}
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant='outlined' className="filter-item">
              <InputLabel>Matter Name</InputLabel>
              <OutlinedInput
                type='text'
                value={matterName}
                onChange={(e) => setMatterName(e.target.value)}
                placeholder='Matter Name'
                label='Matter Name'
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear matter name'
                      onClick={() => setMatterName('')}
                      edge='end'
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant='outlined' className="filter-item">
              <InputLabel>Document ID</InputLabel>
              <OutlinedInput
                type='text'
                value={docId}
                onChange={(e) => setDocId(e.target.value)}
                placeholder='Document ID'
                label='Document ID'
                onKeyDown={handleKeyDown}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='clear document'
                      onClick={() => setDocId('')}
                      edge='end'
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl variant='outlined' className="filter-item">
              <InputLabel>Detected Type</InputLabel>
              <Select
                multiple
                value={detectedType}
                onChange={handleDetectedTypeChange}
                renderValue={(selected) => selected.join(', ')}
              >
                {detectedTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={detectedType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Collapse>
      </>
    );
  };
  const noMattersFound = caseMatters !== null && caseMatters.length === 0;

  return (
    <Container className='outer'>
      {/* Disclaimer Banner */}
      <Alert
        severity="info" color="warning"
        variant="filled"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '1.0rem', // Increase the font size
          padding: '16px 24px', // Add padding for better spacing
          alignItems: 'center', // Vertically center the content
          justifyContent: 'center', // Horizontally center the text
        }}
      >
        Smart Search is currently in Beta. Searchable documents are currently limited to specific offices and attorneys.
        <br/>
        Your feedback is welcome as we continue to improve your search experience. Share feedback <a href="https://feedback.forthepeople.com/litify-suggestions?selectedCategory=smart-search">here</a>
      </Alert>
      <Paper elevation={4} sx={{ width: '100%' }}>
        <CardContent>
          <OutlinedInput
            id='main-input'
            value={searchInput}
            onChange={handleQueryChange}
            onKeyDown={handleKeyDown}
            placeholder='Search'
            variant='outlined'
            fullWidth
            endAdornment={
              <InputAdornment position='end'>
                <IconButton color='info' aria-label='Search' onClick={searchButtonClick} edge='end'>
                  {showSpinner && <RefreshIcon className='spin' />}
                  {!showSpinner && <SearchIcon />}
                </IconButton>
              </InputAdornment>
            } />
        </CardContent>
        {renderFilters()}
      </Paper>
      {noMattersFound && (
      <Alert severity="warning" sx={{ mt: 2 }}>
        No matters found for your account. Showing results that do not match the "My Matters" filter.
      </Alert>
      )}
      <Paper elevation={4}>
        {searchResults.map?.((data, index) => (
          <Paper elevation={6}>
            {[SearchTypes.RRF].includes(searchType) && (
              <RRFResult
                key={index}
                result={data}
                onCheck={props.handleSelect ? () => handleCheckResult(index, data) : undefined}
                checked={!!selections[index]}
              />
            )}
          </Paper>
        ))}
      </Paper>
    </Container>
  );
};
