import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { UploadFile } from '@mui/icons-material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography'; 
import { Tooltip } from '@mui/material';
import { useInterval } from '../../hooks';
import { getDocumentByHash } from '../../interactors/documents';
import { uploadDocument } from '../../interactors/source_sf';
import { getDocumentMetadata } from '../../interactors/documents';

import * as utils from './utils';
import { OCRApiDocument } from '../../types/ocr_document';
import { DocrioUpload } from '../../types/docrio_upload';


interface DocResolverProps {
  checksum: string;
  blob: Blob;
  onResolve: (checksum: string, doc: OCRApiDocument) => void;
  onDelete: (checksum: string) => void
}

const handleDocumentRetrieve = async (fn: Promise<OCRApiDocument>): Promise<OCRApiDocument|undefined> => {
  try {
    return await fn;
  } catch (error: any) {
    if (error?.detail != 'Not found') throw error;
  } 
  return;
};


export const DocResolver = ({ checksum, blob, onResolve, onDelete }: DocResolverProps) => {
  const [pendingDoc, setPendingDoc] = useState<string>('');
  const [percentDone, setPercentDone] = useState<number>(0);
  const [doc, setDoc] = useState<void | OCRApiDocument>(undefined);
  const [errorState, setErrorState] = useState<string>('');

  const isInProgress = (!doc || pendingDoc) && !errorState;

  async function handleDocUploadingError<T>(fn: Promise<T>): Promise<T|undefined> {
    try {
      return await fn;
    } catch (error: any) {
      setErrorState(error?.detail || error?.message);
    }
    return;
  };
  
  useEffect(() => {
    const findOrUploadDoc = async (): Promise<void> => {
      const doc = await handleDocumentRetrieve(getDocumentByHash(checksum));
      if (doc && utils.hasDocumentBeenOCRd(doc)) return setDoc(doc);
      const upload = await uploadDocument(blob);
      setPendingDoc(upload?.document_id ?? '');
    };
    handleDocUploadingError(findOrUploadDoc());
  }, []);

  useInterval(() => {
    const checkPendingDocProgress = async () => {
      if (!pendingDoc) return;
      const document = await handleDocumentRetrieve(getDocumentMetadata(pendingDoc));
      if (!document) return;
      const percentDone = utils.getPercentDone(document);
      setPercentDone(percentDone);
      if (percentDone === 100) {
        setTimeout(() => {
          setPendingDoc('');
          setDoc(document)
        }, 200);
      }
    };
    handleDocUploadingError(checkPendingDocProgress());
  }, isInProgress ? 2000 : null);

  useEffect(() => {
    if (doc) onResolve(checksum, doc);
  }, [doc]);

  
  const displayedPercent = Math.max(percentDone, 5);

  const renderCompleteIcon = () => {
    if (!doc) return null;
    return (
      <IconButton
        disabled
        sx={(theme) => ({'&:disabled': { color: theme.palette.text.secondary }})}
      >
        <CheckCircleIcon />
      </IconButton>
    );
  };

  const renderLinearProgress = () => {
    if (!isInProgress) return null;
    return (
      <LinearProgress
        variant="determinate"
        value={displayedPercent}
      />
    );
  };

  const renderDocLoaderIcon = () => {
    if (errorState) {
      return (
        <Tooltip title={errorState}>
          <ReportProblemIcon color='error'/>
        </Tooltip>
      )
    }
    return <UploadFile />
  };

  return (
    <ListItem
      disablePadding
      secondaryAction={
        <>
          {renderCompleteIcon()}
          <IconButton
            edge="end"
            onClick={() => onDelete(checksum)}
            sx={{marginLeft: '5px' }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      }
    >
      <ListItemIcon>
        {renderDocLoaderIcon()}
      </ListItemIcon>
      <div style={{ width: '60%' }}>
        <div>
          <Typography>{blob.name}</Typography>
          <Typography variant="body2">{utils.showFileSize(blob)}{isInProgress && ` • ${displayedPercent}%`}</Typography>
        </div>
        {renderLinearProgress()}
      </div>
    </ListItem>
  )
};

