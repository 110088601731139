import { APIUrl, getAPIHeaders } from './utils';

export const FileAPIURL = APIUrl.replace('api', 'file.api');

export const getFileImage = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/image`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.blob;
};

export const getFileOrginal = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/original`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.blob();
};

export const getFilePDFOrginal = async (documentID: string) => {
  const response = await fetch(`${FileAPIURL}file/${documentID}/pdf-or-original`, {
    method: 'GET',
    headers: getAPIHeaders(),
  });
  if (!response.ok) throw response;
  return response.body;
};
