import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Link,
  List,
  ListItem,
  Collapse,
  Checkbox,
  Grid,
  Typography,
  useTheme, // Import useTheme to access the current theme
} from '@mui/material';

import { CollabsableRRFItem } from "./CollapsableRRFItem";

export const RRFResult = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const theme = useTheme(); // Use the theme to dynamically get colors

  const stringForType = () => {
    if (props.result[0]._source?.isDoc === undefined || props.result[0]._source?.isDoc === false) {
      return 'Type: Semantic';
    }
    if (props.result[0]._source?.isDoc) {
      return 'Type: Keyword';
    }
  }

  const getDetectedType = (data) => {
    return data._source?.metadata.detected_type || '';
  }

  const getCaseName = (data) => {
    return data._source?.metadata.case_name || '';
  }

  return (
    (Array.isArray(props.result) && (
    <Card elevation={2} sx={{m:4, backgroundColor: theme.palette.background.paper }}>
        <CardHeader
          sx={{ backgroundColor: theme.palette.background.paper }}
          titleTypographyProps={{color: theme.palette.text.primary}}
          title ={
            <>
              {
                props.onCheck && (
                  <Checkbox
                    checked={props.checked}
                    onChange={props.onCheck}
                  />
                )
              }
              <Link
                color="inherit"
                href={props.result[0]._source?.metadata.redirect_url}
                target="_blank"
                className="linked-title">
                {props.result[0]._source?.title}
              </Link>
            </>
          }
          subheader={
            <>
            <Button onClick={toggleOpen} style={{ textAlign: 'left', width: '100%', color: theme.palette.text.secondary }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={6} zeroMinWidth>
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Matter Name: {props.result[0]._source?.metadata.matter_display_name}
                  </Typography>
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Matter ID: {props.result[0]._source?.metadata.matter_id}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} zeroMinWidth>
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Case Name: {getCaseName(props.result[0])}
                  </Typography>
                  <Typography variant="body2" noWrap style={{ wordBreak: "break-word" }}>
                    Detected Type: {getDetectedType(props.result[0])}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
            <Collapse in={isOpen} >
            <List >
              <ListItem>{stringForType()}</ListItem>
              <ListItem>Rank: {parseInt(props.result[0]._rank)}</ListItem>
              <ListItem>Uploaded: {new Date(props.result[0]._source?.metadata.uploaded_date).toLocaleString("en")}</ListItem>
              <ListItem>Last modified :{new Date(props.result[0]._source?.metadata.last_modified_date).toLocaleString("en")}</ListItem>
            </List>
            </Collapse>
          </>}>
        </CardHeader>
        <CardContent>
          <p>
           {((props.result[0]._source?.isDoc === undefined) || (props.result[0]._source?.isDoc === false)) && (
            <>{props.result[0]._source?.text}</>
           )}
           {(props.result[0]._source?.isDoc === true) && (
            <>{props.result[0]._source?.text.slice(0,2000)}</>
           )}
          </p>

        </CardContent>
        <CardActions>
          {(props.result.length > 1) && (
            <CollabsableRRFItem otherHits={props.result.slice(1)}/>
          )}
        </CardActions>

    </Card>
    )))
};
