import pick from 'lodash.pick';
import { FormStateFields } from '../types/promptSandbox';
import { Uploads, Upload } from '../PromptSandbox/types';

const shared_fields = ['model_id', 'prompt_text'];

export const file_input_fields = [...shared_fields, 'file'];

export const text_input_fields = [...shared_fields, 'input_text'];

export const document_input_fields = [...shared_fields, 'document_ids'];

export const query_matter_fields = [...shared_fields, 'matter_id', 'query'];

export const get_file_form_data = (form: FormStateFields, uploads: Uploads) => {
  const document_ids = Object
    .values(uploads)
    .map((upload: Upload) => upload.document.metadata.document_id)
    .filter(Boolean);
  return { ...pick(form, shared_fields), document_ids }
};

export const get_text_input_form_data = (form: FormStateFields): FormStateFields => pick(form, text_input_fields);
export const get_doc_input_form_data = (form: FormStateFields): FormStateFields => pick(form, document_input_fields);
export const get_query_matter_input_form_data = (form: FormStateFields): FormStateFields => pick(form, query_matter_fields);
