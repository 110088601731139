import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import Card from '@mui/material/Card';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { ESSearchModal } from './ElasticsearchModal';
import { NotificationContext } from '../App';
import { getDocumentMetadata } from '../interactors/documents';

const sf_regex = /[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}/;

const wait = () => new Promise((resolve) => setTimeout(resolve, 250));

export default function DocSelector(props) {
  const [input, setInput] = useState('');
  const [fetchingDocMeta, setFetchingDocMeta] = useState(false);
  const [suggestion, setSuggestion] = useState(null);
  const [selected, setSelected] = useState({});
  const [modalSelections, setModalSelections] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setToastMessage } = useContext(NotificationContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!sf_regex.test(input)) {
      setSuggestion(null);
      return;
    }
    setFetchingDocMeta(true);
    Promise.all([getDocumentMetadata(input), wait()]) // cannot async-await in useEffect
      .then((results) => setSuggestion(results[0]))
      .catch(() => {
        setSuggestion(null);
      })
      .finally(() => {
        setFetchingDocMeta(false);
      });
  }, [input]);

  const toggleShowModal = useCallback(() => {
    if (isModalOpen) setModalSelections([]);
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  useEffect(() => {
    props?.onSelect?.(selected);
  }, [selected, props?.onSelect]);

  const handleRemoveSelected = (id) => {
    const newSelected = { ...selected };
    delete newSelected[id];
    setSelected(newSelected);
  };

  const handleAddSelection = (selection) => {
    const newSelected = { ...selected };
    newSelected[selection.metadata.id] = selection;
    setSelected(newSelected);
  };

  const handleAddModalSelections = async () => {
    try {
      const results = await Promise.all(modalSelections.map((selection) => getDocumentMetadata(selection.document_id)));
      const newSelections = results.reduce((acc, selection) => {
        acc[selection.metadata.id] = selection;
        return acc;
      }, {});
      setSelected(newSelections);
    } catch (error) {
      setToastMessage({
        message: JSON.stringify(error),
        severity: 'error',
      });
    } finally {
      toggleShowModal();
    }
  };

  const handleAcceptSuggestion = () => {
    if (suggestion) {
      handleAddSelection(suggestion);
      setInput('');
      setSuggestion(null);
    }
  };

  const handleKeyDown = (e) => {
    if (['Enter', 'Tab'].includes(e.key)) {
      handleAcceptSuggestion();
    }
  };

  const renderSelectedDocuments = () => {
    const selectedValues = Object.values(selected);
    return (
      <div style={{ marginTop: '15px' }}>
        {selectedValues.map((selection) => (
          <Chip
            sx={{ width: '100%', marginRight: '5px' }}
            key={selection.metadata.id}
            label={<Typography noWrap children={selection.metadata.name} />}
            onDelete={() => handleRemoveSelected(selection.metadata.id)}
          />
        ))}
      </div>
    );
  };

  const renderPopoverContent = () => {
    if (fetchingDocMeta) return <CircularProgress />;
    return (
      <Paper color="secondary" onClick={handleAcceptSuggestion}>
        <Typography component="pre">
          {suggestion?.metadata?.name}
          {suggestion?.metadata?.doc_description && `\n${suggestion?.metadata?.doc_description}`}
        </Typography>
      </Paper>
    );
  };

  return (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <ESSearchModal isModalOpen={isModalOpen} toggleShowModal={toggleShowModal} handleSelect={setModalSelections} handleSubmit={handleAddModalSelections} buttonLabel="Get Documents" />
        <TextField
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          inputRef={inputRef}
          placeholder="Enter document ID"
          onKeyDown={handleKeyDown}
        />
        {renderSelectedDocuments()}
        <Popover
          open={fetchingDocMeta || !!suggestion?.metadata?.name}
          anchorEl={inputRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          {renderPopoverContent()}
        </Popover>
      </CardContent>
    </Card>
  );
}
